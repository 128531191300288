import React from 'react';
import splurty from '../../assets/splurty.png';
import nomster from '../../assets/nomster.png';
import flixter from '../../assets/flixter.png';
import grammable from '../../assets/grammable.png';
import todoster from '../../assets/todoster.png';
import flashback from '../../assets/flashback.PNG';

const Projects = () => {
    return (
        <>
        <h3 id="web-apps">Web Apps</h3>
	    <hr className="yellow" />

	    <div className="box">
	      <h4><a href='https://splurty-jose-reynaud.herokuapp.com/'>Quote Generator</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/splurty">Splurty</a></h5>
	      <img src={splurty} className="full-width" />
	      <p>
	          A database-powered quote generator with a mobile-first
	          design, using the Ruby on Rails framework, HTML, and CSS.
	          Uses Git and GitHub for version control, and launched
	          on Heroku.
	      </p>
	    </div>
	    <div className="box">
	      <h4><a href = 'https://nomster-jose-reynaud.herokuapp.com/'>Yelp Clone</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/Nomster">Nomster</a></h5>
	      <img src={nomster} className="full-width" />
	      <p>
	        A Yelp clone that integrates with the Google Maps API
	        and includes features like user comments, star ratings,
	        image uploading, and user authentication.
	      </p>
	    </div>
	    <br className="clear" />

	    <div className="box">
	      <h4><a href = 'https://flixter-jose-reynaud.herokuapp.com/'>Two-Sided Market Place</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/flixter">Flixter</a></h5>
	      <img src={flixter} className="full-width" />
	      <p>
	        A two-sided, video-streaming marketplace platform that
	        features credit card payment capabilities, user role
	        management, complex user interfaces, and advanced
	        database relationships.
	      </p>
	    </div>
	    <div className="box">
	      <h4><a href = 'https://grammable-jose-reynaud.herokuapp.com/'>Test Driven Development</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/Grammable">Grammable</a></h5>
	      <img src={grammable} className="full-width" />
	      <p>
	        An Instagram clone that was built using industry-standard, test-driven
	        development following numerous red/green/refactor cycles.
	      </p>
	    </div>
	    <br className="clear" />
	    <div className="box">
	      <h4><a href = 'https://todoster-jose-reynaud.herokuapp.com/'>Single Page Todo Application</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/Todoster">Todoster</a></h5>
	      <img src={todoster} className="full-width" />
	      <p>
	        This single-page to-do application features a
	        fluid user interface that– by using JavaScript–
	        allows users to rapidly add dynamic content.
	      </p>
	    </div>
	    <div className="box">
	      <h4><a href = 'https://flashback-jose-reynaud.herokuapp.com/'>Interactive Flashcard App</a></h4>
		  <h5>Github: <a href="https://github.com/josereynaud/Flashback">Flashback</a></h5>
	      <img src={flashback} className="full-width" />
	      <p>
	        A simple frontend app using React showing important
			facts about React origins and overall features.
	      </p>
	    </div>
	    <br className="clear" />
        </>
    );
}

export default Projects;
