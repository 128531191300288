import React from 'react';

const Contact = () => {
    return (
        <>
		    <div className="contact-me" id="hire-me">
				<h3 className="contact-headline">Contact</h3>
				<hr className="yellow" />
				<p className="contact-info">
				Currently entertaining new opportunities.
				Please get in touch via email:
				</p>

				<p>
					<a href="mailto:josereynaud@icloud.com" className="contact-link">
						josereynaud@icloud.com
					</a><br /><br />
					<a href="https://www.linkedin.com/in/josereynaud/" className="contact-link">
						LinkedIn
					</a><br /><br />
					<a href="https://github.com/josereynaud" className="contact-link">
						Github
					</a><br />
				</p>
    		</div>
        </>
    );
}

export default Contact;
