import React from 'react';
import portrait from '../../assets/me.jpg';

const About = () => {
    return (
		<div className="about-container">
			<ul>
				<li><a href="#code-samples" className="nav-link">CODE SAMPLES</a></li>
				<li><a href="#web-apps" className="nav-link">WEB APPS</a></li>
				<li><a href="#skills-and-tools" className="nav-link">SKILLS &amp; TOOLS</a></li>
				<li className="hire-me">
					<a href="#hire-me" className="nav-link">HIRE ME</a>
				</li>
			</ul>
			<div className="about">
				<img src={portrait} className="bio_photo" />
				<h1>Jose Reynaud</h1>
				<hr className="hr_grey" />
				<h2>FULL-STACK DEVELOPER</h2>
				<p className="bio">
					A Full Stack Web Developer with 7 years of experience in Network Administration and IT Support, he brings refined troubleshooting skills combined with creativity to tackle down the most complex coding problems. He has extensive experience with Ruby, Rails, JavaScript and building web applications.
				</p>
			</div>
		</div>
    );
}

export default About;
