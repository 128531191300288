import React from 'react';
import github from '../../assets/github.png';
import rubocop from '../../assets/rubocop.png';
import trello from '../../assets/trello.png';
import slack from '../../assets/slack.png';
import codeship from '../../assets/codeship.png';
import skillset from '../../assets/skills.png';

const Skills = () => {
    return (
        <>
            <h3 id="skills-and-tools">Skills &amp; Tools</h3>
            <hr className="yellow" />
            <p className="skills-and-tools">
                Jose has developed proficiency and expertise
                in the following programming languages and
                comfort with the following tools.
            </p>
            <div className="icons">
                <img src={github} className="icon" />
                <img src={rubocop} className="icon" />
                <img src={trello} className="icon" />
                <img src={slack} className="icon" />
                <img src={codeship} className="icon" />
                <br className="clear" />
                <img src={skillset} className="icon"/>
            </div>
        </>
    );
}

export default Skills;
