import React from 'react';
import About from '../common/About';
import Projects from '../common/Projects';
import Skills from '../common/Skills';
import Contact from '../common/Contact';

const Home = () => {
    return (
        <>
            <About />
            <Projects />
            <Skills />
            <Contact />
        </>
    );
}

export default Home;