import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './components/pages/Home';
import './App.css';
import './prism-atom-dark.css';
// import 'https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900'

const App = () => {
  return (  
      <Router>
        <Route path="/" exact component={Home} />
      </Router>
  );
}

export default App;
